<template>
  <div class="parts-container" v-loading="loading">
    <el-table
      ref="table"
      class="table-base"
      size="small"
      :data="tableData"
      stripe
      border
      style="width: 100%"
      height="400px"
      row-key="swId"
    >
      <el-table-column
        label="名称"
        prop="name"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="授权价格类型"
        prop="priceTypes"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{
            getPriceLabel(scope.row.priceTypes)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="出库价格为0的不能上架"
        prop="priceZeroNotUp"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{
            getPriceLabel(scope.row.priceZeroNotUp)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="允许上架到平台的配件性质"
        prop="properties"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{
            scope.row.properties.join(",")
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="允许上传库存到平台的仓库"
        prop="store"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{
            getStoreLabel(scope.row.store)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template>
          <el-button
            type="text"
            size="mini"
            style="margin-right: 16px"
            @click="labelVisible = true"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="有备社区"
      :close-on-click-modal="false"
      :visible.sync="labelVisible"
      width="30%"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="labelForm"
        label-width="200px"
        class="demo-ruleForm"
      >
        <el-form-item label="授权价格类型:" prop="warehouseNames">
          <el-select
            v-model="ruleForm.priceTypes"
            style="width:100%;"
            size="small"
            multiple
            filterable
            placeholder="授权价格类型"
          >
            <el-option
              v-for="item in priceTypesList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出库价格为0的不能上架:" prop="warehouseNames">
          <el-select
            v-model="ruleForm.priceZeroNotUp"
            style="width:100%;"
            size="small"
            multiple
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in priceTypesList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="允许上架到平台的配件性质:" prop="warehouseNames">
          <el-select
            v-model="ruleForm.properties"
            style="width:100%;"
            size="small"
            multiple
            filterable
            placeholder="允许上架到平台的配件性质"
          >
            <el-option
              v-for="item in properties"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="允许上传库存到平台的仓库:" prop="warehouseNames">
          <el-select
            v-model="ruleForm.store"
            style="width:100%;"
            size="small"
            multiple
            filterable
            placeholder="允许上传库存到平台的仓库"
          >
            <el-option
              v-for="item in stores"
              :key="item.depotNo"
              :label="item.depotNo"
              :value="item.depotNo"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancelClick()">取消</el-button>
        <el-button size="small" type="primary" @click="saveClick()"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getM8Setting,
  getPriceTypes,
  getProperties,
  getStore,
  updateSetting,
} from "@/api/m8";

export default {
  data() {
    return {
      tableData: [],
      priceTypesList: [],
      properties: [],
      stores: [],
      outStockCannotShelvesList: [], //出库价格为0的不能上架
      labelVisible: false,
      labelForm: {},
      ruleForm: {},
      rules: {},
    };
  },

  created() {
    this.getM8Setting();
  },
  methods: {
    async getM8Setting() {
      this.loading = true;
      this.priceTypesList = await getPriceTypes();
      this.properties = await getProperties();
      this.stores = await getStore();
      getM8Setting()
        .then((res) => {
          this.ruleForm = {
            name: "有备社区",
            ...res,
          };
          this.tableData = [this.ruleForm];
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.tableData = [];
        });
    },
    cancelClick() {
      this.labelVisible = false;
    },
    saveClick() {
      updateSetting(this.ruleForm)
        .then(() => {
          this.$message.success("更新成功");
          this.getM8Setting();
        })
        .finally(() => {
          this.labelVisible = false;
        });
    },
    getPriceLabel(priceTypes) {
      return priceTypes
        .map(
          (item) => this.priceTypesList.find((sub) => sub.code === item).name
        )
        .join(",");
    },
    getStoreLabel(stores) {
      return stores.join(",");
    },
  },
};
</script>

<style lang="less" scoped>
.parts-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
  /deep/ .table-base {
    margin-top: @margin-size-main;
    .el-table__expanded-cell {
      padding: 0;
    }
    td .cell {
      padding-top: 1px;
      padding-bottom: 1px;
    }
  }
  .table-bottom {
    display: flex;
    align-items: center;
    margin-top: @margin-size-secondary;
    height: 32px;
    position: relative;
    .pagination-base {
      align-self: flex-end;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
</style>
