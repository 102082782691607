<template>
  <div class="parts-container">
    <Search activeTab="On" @handleQuery="handleQuery" />
    <div class="parts-container-inside" v-loading="loading">
      <el-table
        ref="table"
        class="table-base"
        size="small"
        :data="tableData"
        stripe
        border
        style="width: 100%"
        height="400px"
        row-key="swId"
        @selection-change="selectionChange"
      >
        <el-table-column
          type="selection"
          header-align="center"
          width="43"
        ></el-table-column>
        <el-table-column
          label="配件ID"
          prop="partId"
          align="center"
          min-width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="配件编码"
          prop="partNo"
          align="center"
          min-width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="配件名称"
          prop="partName"
          align="center"
          min-width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="配件品牌"
          prop="brand"
          align="center"
          min-width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="配件产地"
          prop="factory"
          align="center"
          min-width="150"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="实际库存数"
          prop="qty"
          align="center"
          min-width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="动态库存数"
          prop="vQty"
          align="center"
          min-width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="可上架库存数"
          prop="putawayVQty"
          align="center"
          min-width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="保险认证分类"
          prop="insurCertType"
          align="center"
          min-width="150"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="规格"
          prop="model"
          align="center"
          min-width="150"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="车型"
          prop="vehModel"
          align="center"
          min-width="150"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="生产码"
          prop="partNoA"
          align="center"
          min-width="150"
          show-overflow-tooltip
        ></el-table-column
        ><el-table-column
          label="部位码"
          prop="swPosCode"
          align="center"
          min-width="150"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="计量单位"
          prop="unit"
          align="center"
          min-width="150"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="失败原因"
          prop="error"
          align="center"
          min-width="150"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
      <div class="table-bottom">
        <template>
          <el-checkbox v-model="selectionAll" :disabled="!tableData.length">
            全选本页查询结果
          </el-checkbox>
          <el-divider direction="vertical" class="divider-base"></el-divider>
          <span>
            已选中
            {{ selectionAll ? pageData.total : selections.length }}
            条
          </span>
          <!-- <el-divider direction="vertical" class="divider-base"></el-divider>
          <span>批量操作</span> -->
          <el-button
            class="btn_part"
            size="mini"
            type="primary"
            :disabled="!(selectionAll || selections.length)"
            @click="changePart(false)"
          >
            下架选中配件
          </el-button>
          <el-button
            class="btn_part"
            size="mini"
            type="primary"
            @click="changePart(true)"
          >
            下架全部配件
          </el-button>
        </template>
        <el-pagination
          class="pagination-base"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          background
          :current-page="pageData.page"
          :page-sizes="pageData.pageSizes"
          :page-size="pageData.pageSize"
          :pager-count="pageData.pagerCount"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getPartUpList, deAuthParts } from "@/api/m8";
import Search from "../components/search.vue";

const defaultFormData = {
  code: "",
  brand: "",
  name: "",
  codeMatch: "",
  status: 0,
  joinStock: 0,
  toPlatform: 0,
};

export default {
  components: {
    Search,
  },
  data() {
    return {
      selections: [],
      selectionAll: false,
      pageData: {
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 20,
        total: 0,
        pagerCount: 5,
      },
      formData: {
        ...defaultFormData,
      },
      loading: false,
      tableData: [],
    };
  },
  watch: {
    selectionAll: {
      handler: function(val) {
        if (val) {
          const tableEl = this.$refs.table;
          this.tableData.forEach((row) => {
            tableEl.toggleRowSelection(row);
          });
        } else {
          this.$nextTick(() => {
            const tableEl = this.$refs.table;
            tableEl.clearSelection();
          });
        }
      },
    },
  },
  created() {
    this.getList();
  },
  methods: {
    // 查询
    handleQuery(query) {
      this.formData = {
        ...query,
      };
      this.selectionAll = false;
      this.pageData.page = 1;
      this.getList();
    },
    // 重置
    handleReset() {
      Object.assign(this.formData, JSON.parse(JSON.stringify(defaultFormData)));
    },
    getList() {
      this.loading = true;
      const params = {
        ...this.formData,
        page: this.pageData.page,
        pageSize: this.pageData.pageSize,
      };
      getPartUpList(params)
        .then((res) => {
          this.loading = false;
          this.tableData = res.rows;
          this.pageData.total = res.totalSize || 0;
        })
        .catch(() => {
          this.loading = false;
          this.pageData.page = 1;
          this.pageData.total = 0;
          this.tableData = [];
        });
    },
    // 复选框事件
    selectionChange(val) {
      this.selections = val;
    },
    handleSizeChange(val) {
      this.pageData.page = 1;
      this.pageData.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.page = val;
      this.getList();
    },
    // 没有id时无法勾选
    handleSelectable(row) {
      return !!row.id;
    },
    changePart(isAll) {
      const partIds = this.selections.map((item) => item.partId);
      deAuthParts({
        partIds: isAll ? null : partIds,
        isAll,
      }).then(() => {
        this.$message.success("配件下架成功!");
        this.getList();
      });
    },
    toggleRowExpansion() {
      const tableEl = this.$refs.table;
      if (tableEl) {
        this.tableData.forEach((item) => {
          tableEl.toggleRowExpansion(item, false);
        });
      }
    },
    // 编辑
  },
};
</script>

<style lang="less" scoped>
.parts-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .parts-container-inside {
    height: calc(100% - 80px);
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  /deep/ .table-base {
    margin-top: @margin-size-main;
    .el-table__expanded-cell {
      padding: 0;
    }
    td .cell {
      padding-top: 1px;
      padding-bottom: 1px;
    }
  }
  .table-bottom {
    display: flex;
    align-items: center;
    margin-top: @margin-size-secondary;
    height: 32px;
    position: relative;
    .btn_part {
      margin-left: 4px;
      padding: 5px 8px;
    }
    .pagination-base {
      align-self: flex-end;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
</style>
