<template>
  <div class="vip-card">
    <el-tabs v-model="activeTab">
      <el-tab-pane
        v-for="(tab, index) in tabs"
        :key="index"
        :label="tab.name"
        :name="tab.id"
      >
        <components
          v-if="activeTab === tab.id"
          :is="tab.id"
          @tabChange="handlerTabChange"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import On from "./on/index.vue";
import Off from "./off/index.vue";
import Process from "./process";
import ErrLogs from "./errLogs";

export default {
  components: {
    On,
    Off,
    Process,
    ErrLogs
  },
  data() {
    return {
      activeTab: "On",
      defaultTab: "On",
      tabs: [
        { id: "On", name: "已上架配件" },
        { id: "Off", name: "未上架配件" },
        { id: "Process", name: "处理中" },
        { id: "ErrLogs", name: "失败日志" },
      ],
    };
  },
  mounted() {
    this.activeTab = "On";
  },
  methods: {
    handlerTabChange(tabName) {
      this.activeTab = tabName;
    },
  },
};
</script>

<style lang="less" scoped>
.vip-card {
  height: 100%;
  position: relative;
  .el-tabs {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /deep/ .el-tabs__header {
      margin: 0;
    }
    /deep/ .el-tabs__content {
      flex: 1;
      padding: @padding-size-main;
      .el-tab-pane {
        height: 100%;
      }
    }
  }
}
</style>
