<template>
  <el-form
    class="form-search"
    size="small"
    :inline="true"
    :model="formData"
    :label-width="labelWidth"
  >
    <el-row>
      <el-col :span="6">
        <el-form-item label="配件编码">
          <el-input
            placeholder="配件编码"
            v-model="formData.code"
            style="max-width: 270px;"
          >
            <el-select
              v-model="formData.codeMatch"
              slot="append"
              placeholder=""
            >
              <el-option
                v-for="(item, index) in codeMatchOpts"
                :key="index"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="配件品牌">
          <el-input
            v-model="formData.brand"
            placeholder="配件品牌"
            style="max-width: 270px;"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="配件信息">
          <el-input
            v-model="formData.name"
            placeholder="输入配件名称、规格、车型等"
            style="max-width: 270px;"
          ></el-input>
        </el-form-item>
        <el-form-item label="配件产地">
          <el-input
            v-model="formData.productionPlace"
            placeholder="配件产地"
            style="max-width: 270px;"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="配件分类">
          <el-input
            v-model="formData.category"
            placeholder="配件分类"
            style="max-width: 270px;"
          ></el-input>
        </el-form-item>
        <el-form-item label="库存状态" v-if="activeTab !== 'ErrLogs'">
          <el-select
            v-model="formData.joinStock"
            style="max-width: 270px;"
            placeholder="库存状态"
          >
            <el-option
              v-for="(item, index) in joinStockOpts"
              :key="index"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="activeTab == 'ErrLogs'"
          label="失败时间"
          label-width="70px"
        >
          <el-date-picker
            v-model="formData.startDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="开始日期"
          >
          </el-date-picker>
          <span> 至 </span>
          <el-date-picker
            v-model="formData.endDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item
          v-if="activeTab == 'Off'"
          label="允许上架平台"
          label-width="100px"
        >
          <el-select
            v-model="formData.toPlatform"
            style="max-width: 270px;"
            placeholder="允许上架平台"
          >
            <el-option
              v-for="(item, index) in toPlatformOpts"
              :key="index"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="activeTab == 'Process'"
          label="上下架状态"
          label-width="100px"
        >
          <el-select
            v-model="formData.status"
            style="max-width: 270px;"
            placeholder="上下架状态"
          >
            <el-option
              v-for="(item, index) in statusOpts"
              :key="index"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        
        <slot name="query">
          <el-button size="small" type="primary" @click="handleQuery">
            查询
          </el-button>
        </slot>
        <slot name="reset">
          <el-button size="small" @click="handleReset">重置</el-button>
        </slot>
      </el-col>
    </el-row>
    <!-- </div>
    </div> -->
  </el-form>
</template>

<script>
export default {
  name: "SearchTemplate",
  props: {
    activeTab: {
      type: String,
      default: "On",
    },
  },
  data() {
    return {
      labelWidth: "70px",
      searchSwitch: false,
      formData: {
        name: "",
        brand: "",
        code: "",
        codeMatch: "",
        status: 0,
        joinStock: 0,
        toPlatform: 0,
      },
      codeMatchOpts: [
        //模糊匹配
        { code: "", name: "模糊匹配" },
        { code: "left", name: "左匹配" },
        { code: "right", name: "右匹配" },
        { code: "full", name: "全匹配" },
      ],
      joinStockOpts: [
        //库存状态
        { code: 0, name: "全部" },
        { code: 1, name: "有货" },
        { code: 2, name: "无货" },
      ],
      toPlatformOpts: [
        //允许上架平台
        { code: 0, name: "全部" },
        { code: 1, name: "是" },
        { code: 2, name: "否" },
      ],
      statusOpts: [
        //上下架状态
        { code: 0, name: "全部" },
        { code: 2, name: "上架中" },
        { code: 4, name: "下架中" },
      ],
    };
  },
  methods: {
    handleQuery() {
      this.$emit("handleQuery", this.formData);
    },
    handleReset() {
      this.formData = {
        name: "",
        brand: "",
        code: "",
        codeMatch: "",
        status: 0,
        joinStock: 0,
        toPlatform: 0,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.form-search {
  padding: 5px 10px;
  background-color: #ffffff;
  /deep/ .el-form-item {
    display: flex;
    margin-bottom: 12px;
    .el-form-item__content {
      flex: 1;
    }
  }
  /deep/ .el-date-editor {
    max-width: 270px;
    .el-range-separator {
      padding: 0 2px;
      width: auto;
    }
    .el-input__inner {
      padding-left: 24px;
      padding-right: 12px;
    }
    .el-input__icon {
      width: 16px;
    }
  }
  /deep/.el-date-editor.el-input {
    width: 123px;
  }
  /deep/.el-input-group__append .el-select .el-input {
    width: 100px;
  }
}
</style>
