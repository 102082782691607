<template>
  <div class="parts-container">
    <Search activeTab="ErrLogs" @handleQuery="handleQuery" />
    <div class="parts-container-inside" v-loading="loading">
      <el-table
        ref="table"
        class="table-base"
        size="small"
        :data="tableData"
        stripe
        border
        style="width: 100%"
        height="400px"
      >
        <el-table-column
          label="序号"
          type="index"
          align="center"
          width="60"
        ></el-table-column>
        <el-table-column
          label="配件ID"
          prop="partId"
          align="center"
          min-width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="配件编码"
          prop="partNo"
          align="center"
          min-width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="配件名称"
          prop="partName"
          align="center"
          min-width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="配件品牌"
          prop="brand"
          align="center"
          min-width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="配件产地"
          prop="factory"
          align="center"
          min-width="150"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="日志类型"
          prop="typeName"
          align="center"
          min-width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="失败原因"
          prop="message"
          align="center"
          min-width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="失败时间"
          prop="time"
          align="center"
          min-width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="保险认证分类"
          prop="insurCertType"
          align="center"
          min-width="150"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="规格"
          prop="model"
          align="center"
          min-width="150"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="车型"
          prop="vehModel"
          align="center"
          min-width="150"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="生产码"
          prop="partNoA"
          align="center"
          min-width="150"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="部位码"
          prop="swPosCode"
          align="center"
          min-width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.info ? scope.row.info.swPosCode : "" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="计量单位"
          prop="unit"
          align="center"
          min-width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.info ? scope.row.info.unit : "" }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-bottom">
        <el-pagination
          class="pagination-base"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          background
          :current-page="pageData.page"
          :page-sizes="pageData.pageSizes"
          :page-size="pageData.pageSize"
          :pager-count="pageData.pagerCount"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getPartFailLogsList } from "@/api/m8";
import Search from "../components/search.vue";

const defaultFormData = {
  code: "",
  brand: "",
  name: "",
  codeMatch: "",
  status: 0,
  joinStock: 0,
  toPlatform: 0,
};

export default {
  components: {
    Search,
  },
  data() {
    return {
      pageData: {
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 20,
        total: 0,
        pagerCount: 5,
      },
      formData: {
        ...defaultFormData,
      },
      loading: false,
      tableData: [],
    };
  },
  // watch: {
  //   selectionAll: {
  //     handler: function(val) {
  //       if (val) {
  //         const tableEl = this.$refs.table;
  //         this.tableData.forEach((row) => {
  //           tableEl.toggleRowSelection(row);
  //         });
  //       } else {
  //         this.$nextTick(() => {
  //           const tableEl = this.$refs.table;
  //           tableEl.clearSelection();
  //         });
  //       }
  //     },
  //   },
  // },
  created() {
    this.getList();
  },
  methods: {
    // 查询
    handleQuery(query) {
      this.formData = {
        ...query,
      };
      this.selectionAll = false;
      this.pageData.page = 1;
      this.getList();
    },
    // 重置
    handleReset() {
      Object.assign(this.formData, JSON.parse(JSON.stringify(defaultFormData)));
    },
    getList() {
      this.loading = true;
      const params = {
        ...this.formData,
        page: this.pageData.page,
        pageSize: this.pageData.pageSize,
      };
      getPartFailLogsList(params)
        .then((res) => {
          this.loading = false;
          this.tableData = res.rows;
          this.pageData.total = res.totalSize || 0;
        })
        .catch(() => {
          this.loading = false;
          this.pageData.page = 1;
          this.pageData.total = 0;
          this.tableData = [];
        });
    },
    handleSizeChange(val) {
      this.pageData.page = 1;
      this.pageData.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.page = val;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.parts-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .parts-container-inside {
    // height: calc(100% - 140px);
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  /deep/.table-base {
    margin-top: @margin-size-main;
    .el-table__expanded-cell {
      padding: 0;
    }
    td .cell {
      padding-top: 1px;
      padding-bottom: 1px;
    }
  }
  .table-bottom {
    display: flex;
    align-items: center;
    margin-top: @margin-size-secondary;
    height: 32px;
    position: relative;
    .pagination-base {
      align-self: flex-end;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
</style>
