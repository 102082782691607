<template>
  <div class="vip-card">
    <el-tabs type="card" v-model="activeTab" @tab-click="changeTab">
      <el-tab-pane
        v-for="(tab, index) in tabs"
        :key="index"
        :label="tab.name"
        :name="tab.id"
      >
        <components
          v-if="activeTab === tab.id"
          :is="tab.id"
          @tabChange="handlerTabChange"
        />
      </el-tab-pane>
    </el-tabs>
    <!-- <el-button
      type="primary"
      size="mini"
      round
      class="gradients-button"
      @click="m8"
    >
      去有备社区首页
    </el-button> -->
  </div>
</template>

<script>
import { TabChangeMixin } from "@/mixins/tabChangeMixin.js";
import Parameters from "./parameters/index.vue";
import Shelves from "./shelves/index.vue";

export default {
  mixins: [TabChangeMixin],
  components: {
    Parameters,
    Shelves,
  },
  data() {
    return {
      activeTab: "Parameters",
      defaultTab: "Parameters",
      tabs: [
        { id: "Parameters", name: "参数设置" },
        { id: "Shelves", name: "配件上下架" },
      ],
    };
  },
  methods: {
    handlerTabChange(tabName) {
      this.activeTab = tabName;
    },
    m8() {
      let url = window.location.origin;
      if (sessionStorage.token) {
        url += "/?token=" + sessionStorage.token;
      }
      if (window.ChromiumWebBrowser) {
        window.ChromiumWebBrowser.JsOpenBrowser(url);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.vip-card {
  height: 100%;
  position: relative;
  .el-tabs {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /deep/ .el-tabs__header {
      margin: 0;
    }
    /deep/ .el-tabs__content {
      flex: 1;
      padding: 5px;
      .el-tab-pane {
        height: 100%;
      }
    }
  }
  .gradients-button {
    position: absolute;
    right: 50px;
    top: 7px;
    z-index: 100;
  }
}
</style>
